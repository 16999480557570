import '@landing/styles/globals.css';

import { GTagScript } from '@landing/src/components/GTagScript';
import { NavigationLogger } from '@landing/src/components/NavigationLogger';
import store from '@landing/src/store';
import _ from 'lodash-es';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    console.warn(process.env.NEXT_PUBLIC_BUILD_TIME);
    import('aos').then((aos) => aos.init());
  }, []);

  const router = useRouter();

  // 페이지 간에 redirect_uri를 공유하기 위해 sesstionStorage에 저장
  useEffect(() => {
    const { redirect_uri } = router.query;
    if (typeof redirect_uri === 'string') {
      sessionStorage.setItem('redirect-uri', redirect_uri);
      router.replace(
        { query: _.omit(router.query, 'redirect_uri') },
        undefined,
        { shallow: true },
      );
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>
          나만의 AI목소리 제작, 텍스트 음성변환 TTS 사이트 : KT AI 보이스
          스튜디오
        </title>
        <meta name="google" content="notranslate" />
        <meta
          name="description"
          content="생생한 감정과 5개 국어가 가능한 AI보이스와 내 목소리로 만드는 마이AI보이스로 유튜브, 오디오북, 안내방송, 도슨트 등 다양한 콘텐츠를 제작해보세요!"
        />
        <meta
          property="og:title"
          content="나만의 AI목소리 제작, 텍스트 음성변환 TTS 사이트 : KT AI 보이스 스튜디오"
        />
        <meta
          property="og:description"
          content="생생한 감정과 5개 국어가 가능한 AI보이스와 내 목소리로 만드는 마이AI보이스로 유튜브, 오디오북, 안내방송, 도슨트 등 다양한 콘텐츠를 제작해보세요!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={process.env.NEXT_PUBLIC_HOME_URL} />
        <meta property="og:image" content="/images/img_og.jpg" />
        <link rel="icon" type="image/svg+xml" href="/logo-kt.svg" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
      </Head>

      <NavigationLogger>
        <Provider store={store}>
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...pageProps}
          />
        </Provider>
      </NavigationLogger>

      <GTagScript />
    </>
  );
}
