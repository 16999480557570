import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect } from 'react';

interface Props {
  readonly children: ReactElement;
}

export const NavigationLogger = (props: Props) => {
  const router = useRouter();

  useEffect(() => {
    const beforeHistoryChangeHandler = (e: string) => {
      console.log('[NavigationLogger - beforeHistoryChange]', e);
    };
    const routeChangeStartHandler = (e: string) => {
      console.log('[NavigationLogger - routeChangeStart]', e);
    };
    const routeChangeCompleteHandler = (e: string) => {
      console.log('[NavigationLogger - routeChangeComplete]', e);
    };
    const routeChangeErrorHandler = (e: string) => {
      console.log('[NavigationLogger - routeChangeError]', e);
    };
    router.events.on('beforeHistoryChange', beforeHistoryChangeHandler);
    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);
    router.events.on('routeChangeError', routeChangeErrorHandler);
    return () => {
      router.events.off('beforeHistoryChange', beforeHistoryChangeHandler);
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
      router.events.off('routeChangeError', routeChangeErrorHandler);
    };
  }, [router.events]);

  return props.children;
};
